import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import { calendarOutline, rocketOutline, barChartOutline, cogOutline, analyticsOutline } from 'ionicons/icons';
import GrowthPage from './pages/Growth';
import ForecastAndYoYPage from './pages/ForecastAndYoY';
import MarketingPage from './pages/Marketing';
import RenewalsPage from './pages/Renewals';
import OperationsPage from './pages/Operations';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactHashRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/growth">
            <GrowthPage />
          </Route>
          <Route exact path="/forecast">
            <ForecastAndYoYPage />
          </Route>
          <Route exact path="/marketing">
            <MarketingPage />
          </Route>
          <Route path="/renewals">
            <RenewalsPage />
          </Route>
          <Route path="/operations">
            <OperationsPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/growth" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="growth" href="/growth">
            <IonIcon icon={barChartOutline} />
            <IonLabel>Growth</IonLabel>
          </IonTabButton>
          <IonTabButton tab="forecast" href="/forecast">
            <IonIcon icon={analyticsOutline} />
            <IonLabel>YoY & Forecast</IonLabel>
          </IonTabButton>
          <IonTabButton tab="marketing" href="/marketing">
            <IonIcon icon={rocketOutline} />
            <IonLabel>Marketing</IonLabel>
          </IonTabButton>
          <IonTabButton tab="renewals" href="/renewals">
            <IonIcon icon={calendarOutline} />
            <IonLabel>Renewals & Retention</IonLabel>
          </IonTabButton>
          <IonTabButton tab="operations" href="/operations">
            <IonIcon icon={cogOutline} />
            <IonLabel>Operations</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactHashRouter>
  </IonApp>
);

export default App;
